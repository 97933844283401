import React from 'react';
import BreadcrumbSchema from '../../components/BreadcrumbSchema';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { FaShoppingCart } from 'react-icons/fa';
import ServiceTemplate from '../../components/service/ServiceTemplate';

const WebNew = ({ data }) => {
  const seoData = {
    title: 'ECサイト構築｜奈良のホームページ制作なら｜インヴォルブ',
    description:
      'オンラインショップをゼロから構築し、売れる仕組みを作ります。ブランドイメージに合わせた 独自デザイン のECサイトを制作し、集客・売上向上をサポート。使いやすさと機能性を兼ね備えた、成果につながるECサイトを実現します。',
    pageUrl: 'https://www.involve-in.jp/service/ec',
    ogImage: 'https://www.involve-in.jp/images/common/ogp.png',
  };

  const breadcrumbList = [
    { name: 'ホーム', url: 'https://www.involve-in.jp/' },
    { name: 'サービス一覧', url: 'https://www.involve-in.jp/service/' },
    { name: 'ECサイト構築', url: seoData.pageUrl },
  ];

  const works = data.allContentfulCaseStudy.nodes; // Contentful から施工事例を取得

  return (
    <>
      {/* ✅ 構造化パンくずデータを渡す */}
      <BreadcrumbSchema breadcrumbList={breadcrumbList} />
      <ServiceTemplate
        title="ECサイト構築"
        subtitle="ネットショップ開発"
        icon={FaShoppingCart}
        description="オンラインショップをゼロから構築し、売れる仕組みを作ります。ブランドイメージに合わせた 独自デザイン のECサイトを制作し、集客・売上向上をサポート。使いやすさと機能性を兼ね備えた、成果につながるECサイトを実現します。"
        seo={{
          title: 'ECサイト構築｜奈良のホームページ制作なら｜インヴォルブ',
          description:
            'オンラインショップをゼロから構築し、売れる仕組みを作ります。ブランドイメージに合わせた 独自デザイン のECサイトを制作し、集客・売上向上をサポート。使いやすさと機能性を兼ね備えた、成果につながるECサイトを実現します。',
          pageUrl: 'https://www.involve-in.jp/service/ec',
          ogImage: 'https://www.involve-in.jp/images/common/ogp.png',
        }}
        breadcrumbs={[
          { name: 'ホーム', url: 'https://www.involve-in.jp/' },
          {
            name: 'サービス一覧',
            url: 'https://www.involve-in.jp/service/',
          },
          {
            name: 'ECサイト構築',
            url: 'https://www.involve-in.jp/service/ec',
          },
        ]}
        benefits={[
          {
            title: 'オリジナルデザイン',
            description:
              'テンプレートに頼らず、ブランドの個性を際立たせるオリジナルデザインのECサイトを制作。他とは違う、唯一無二のオンラインショップを実現します。',
          },
          {
            title: '決済・配送機能',
            description:
              'クレジットカード決済、銀行振込、各種電子マネー対応の決済システムを導入。さらに、配送管理機能も搭載し、スムーズな受注・発送をサポートします。',
          },
          {
            title: '売上アップ施策',
            description:
              'SEO対策、広告運用、SNS連携など、集客施策までしっかりサポート。ただ作るだけでなく、売れるECサイトを目指します。',
          },
        ]}
        flow={[
          {
            title: '企画・設計',
            duration: '2週間',
            description:
              'ECサイトの目的やターゲットに合わせた設計を行います。当事務所は マーケティングにも強い ため、売上につながる戦略的な企画・設計についても しっかりご相談に乗れます。',
          },
          {
            title: 'デザイン制作',
            duration: '2週間',
            description:
              'ブランドイメージに沿った 購入しやすく、信頼を得られるデザインを作成。視覚的にも訴求力の高い、魅力的なECサイトをデザインします。',
          },
          {
            title: '機能開発',
            duration: '3週間',
            description:
              'クレジットカード決済、商品管理、注文管理などのECサイトに必要な機能を実装。より良い仕上がりにするため、お客様との対話や確認を大切に進めます。',
          },
          {
            title: 'テスト・公開',
            duration: '1週間',
            description:
              'サイト全体の動作を入念にチェックし、不具合がないことを確認したうえで正式公開。スムーズな運用ができるよう、必要に応じてサポートも行います。',
          },
        ]}
        preparation={[
          {
            title: '販売商品・価格の整理',
            description:
              'どの商品を販売するのか、価格設定はどうするのかを明確にしましょう。事前に整理いただくことで、スムーズにサイト設計が進みます。',
          },
          {
            title: '決済・配送方法の確認',
            description:
              'クレジットカード決済、銀行振込、スマホ決済（QRコード決済）など、対応する決済手段を決定してください。また、配送方法（宅配便、メール便、送料無料条件など）もご確認ください。',
          },
          {
            title: 'ブランディング方針',
            description:
              'ECサイトのデザインやトーンを統一するため、ロゴ・ブランドカラー・フォントなどの資料をご用意ください。商品パッケージのデザインなどがあると、サイト制作の方向性がより明確になります。',
          },
          {
            title: '運営のイメージ',
            description:
              'ECサイト運営の流れ（受注管理、在庫管理、カスタマー対応など）について、貴社内での運営方針があればお聞かせください。それに合わせた最適な提案が可能になります。',
          },
        ]}
        works={works.map((work) => ({
          title: work.title,
          image:
            work.image && work.image.length > 0
              ? getImage(work.image[0].gatsbyImageData)
              : null, // 🔥 最初の画像のみ取得
        }))}
        price="¥600,000〜"
        cta="まずは無料相談"
      />
      {/* ✅ 表示用のパンくずは ServiceTemplate で管理 */}
    </>
  );
};

// GraphQL クエリ（Contentful の施工事例を取得）
export const query = graphql`
  query {
    allContentfulCaseStudy(sort: { publishDate: DESC }) {
      nodes {
        title
        slug
        image {
          gatsbyImageData(
            layout: CONSTRAINED
            formats: [AUTO, WEBP]
            placeholder: BLURRED
          )
        }
      }
    }
  }
`;

export default WebNew;
